import './kendo.scss';
import './jquery';

// kendo ui stuff
import 'pako/dist/pako_deflate';
import '@progress/kendo-ui/js/kendo.all';
import '@progress/kendo-ui/js/kendo.aspnetmvc';

import JSZip from 'jszip';
window.JSZip = JSZip; // https://www.telerik.com/forums/kendoui-and-webpack-how-to-load-jszip

// Set kendo license key (must be renewed)
// https://docs.telerik.com/kendo-ui/intro/installation/using-license-code#1-get-a-license-file
// eslint-disable-next-line
KendoLicensing.setScriptKey('141j044b041h541j4i1d542e582i511k0b1i111b141c0j1e1623101e0f304e3f102d4e21512f50204h2d593h5j3e5i335h395f315d3f5f404g2f103f531i4e224k1i592h5f2d5h204i1e5e280g2i0d2h022g082e002c0e2e0k1f5g410e224j1d531j4j285i235f2j1a29022a0d2b072d0f2f012d16215d46011e4k234f1i4k2j5b1h4f1d4d2b122h0a2i052j0f30072709300j1e601e0f23135g3g5k3a5k3b5i424c022a041k18253a5i42560c2h0e1e0i57115c31580k593b210i1e01230f4j0j461k3k4j3h2c53200i571e322b5k104f0a3j1d532b5a25004d2e051i5h');